import React, { useEffect, useState } from "react";
import { cn } from "../lib/helpers";

import { Link } from "gatsby";

import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";

import PortableText from "./portableText";
import BrushImage from "./brushImage";

import * as styles from "./article.module.css";
import * as commonStyles from "../styles/common.module.css";

const getInterview = (title) => {
  const interview1 = title.split("Rozhovor s ")[1];
  const interview2 = title.split("Rozhovor se ")[1];

  if (interview1) return { preTitle: "Rozhovor s", title: interview1 };
  if (interview2) return { preTitle: "Rozhovor se", title: interview2 };

  return { title };
};

function Article({ article = {} }) {
  const intTitle = getInterview(article.title);

  return (
    <div className={styles.container}>
      <h1 className={cn(styles.headline, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        Magazín
      </h1>

      {intTitle.preTitle && (
        <h3
          className={cn(styles.pretitle, commonStyles.colorAccent, commonStyles.fontButlerMedium)}
        >
          {intTitle.preTitle}
        </h3>
      )}
      <h2 className={cn(styles.title, commonStyles.colorAccent, commonStyles.fontButlerMedium)}>
        {intTitle.title}
      </h2>
      {article._rawExcerpt && (
        <div className={cn(styles.prebody, commonStyles.colorAccent, commonStyles.fontButler)}>
          <PortableText blocks={article._rawExcerpt} />
        </div>
      )}
      {article._rawBody && (
        <div className={cn(styles.body, commonStyles.colorDarkGray, commonStyles.fontButler)}>
          <PortableText blocks={article._rawBody} />
          <div className={styles.mainImage}>
            {article.mainImage && <BrushImage image={article.mainImage} />}
          </div>
        </div>
      )}

      {article.galery?.length > 0 && (
        <ul className={styles.masonry}>
          {article.galery.map((image, index) => {
            const imageData = getGatsbyImageData(image, {}, clientConfig.sanity);
            return (
              <li key={`article_${index}`} className={styles.item}>
                <GatsbyImage image={imageData} alt={article.mainImage?.alt} />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default Article;
