import React from "react";
import { graphql } from "gatsby";

import Layout from "../containers/layout";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import PageContainer from "../components/pageContainer";
import Article from "../components/article";

import { toPlainText } from "../lib/helpers";

export const query = graphql`
  query ArticleTemplateQuery($id: String!) {
    allServices: allSanityService(sort: { fields: title }) {
      nodes {
        title
        disabled
        slug {
          current
        }
      }
    }
    magazinPreview: allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 6) {
      nodes {
        title
        slug {
          current
        }
      }
    }
    tags: allSanityCategory(sort: { fields: title }) {
      nodes {
        title
        _id
      }
    }
    article: sanityPost(id: { eq: $id }) {
      id
      categories {
        _id
        title
      }
      mainImage {
        ...SanityImage
        alt
      }
      title
      _rawExcerpt(resolveReferences: { maxDepth: 5 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      galery {
        asset {
          _id
          metadata {
            lqip
            dimensions {
              width
              height
              aspectRatio
            }
          }
        }
        crop {
          top
          right
          left
          bottom
          _type
          _key
        }
        hotspot {
          y
          x
          width
          height
          _type
          _key
        }
      }
    }
  }
`;

const ArticleTemplate = (props) => {
  const { data, errors } = props;
  const article = data && data?.article;
  const allServices = data && data?.allServices.nodes;
  const magazinPreview = data && data?.magazinPreview?.nodes;
  const tags = data && data?.tags?.nodes;
  return (
    <Layout services={allServices} magazin={magazinPreview} tags={tags} logoAnimation={false}>
      {errors && <SEO title="GraphQL Error" />}
      {article && (
        <SEO
          title={article.title || "Untitled"}
          description={toPlainText(article._rawExcerpt)}
          image={article.mainImage}
        />
      )}
      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      <PageContainer>
        <Article article={article} />
      </PageContainer>
    </Layout>
  );
};

export default ArticleTemplate;
